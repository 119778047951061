"use strict";


const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";
//const baseURL="https://britishvitamins-212611-ruby.b212611.dev.eastus.az.svc.builder.cafe";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
